<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} ingredients</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">
        Found {{meta.total}} ingredients that match '{{query}}'
      </span>
      <router-link
        v-if="can(uiPermissions.INGREDIENTS_CREATE)"
        to="/ingredients/new"
        data-test="btn-new-ingredient"
        class="btn btn-primary ml-auto">
        New ingredient
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-ingredients" class="table table-hover table-outline table-vcenter card-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>
                    <i v-b-tooltip="TOOLTIP_TAGS" class="fe mr-2 fe-info"></i>
                    Tags
                  </th>
                  <th>
                    <i v-b-tooltip="TOOLTIP_ALLOWED_IN_RECIPE" class="fe mr-2 fe-info"></i>
                    For recipes
                  </th>
                  <th>Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-nowrap">
                    <div class="d-flex align-items-center">
                      <router-link
                        v-if="can(uiPermissions.INGREDIENTS_VIEW)"
                        :to="`/ingredients/${item.id}`">
                        {{item.name}}
                      </router-link>
                      <span v-else>{{item.name}}</span>
                      <i
                        v-if="!item.is_active"
                        v-b-tooltip="'This ingredient is inactive'"
                        title="This ingredient is inactive"
                        class="text-warning fe fe-alert-triangle ml-2"
                        aria-hidden="true"></i>
                    </div>
                  </td>
                  <td>
                    {{(item.category || {}).name || 'N/A'}}
                  </td>
                  <td>
                    {{item.tags.map(t => t.name).join(', ')}}
                  </td>

                  <td class="text-capitalize">
                    <i v-if="item.allowed_in_recipes" class="fe fe-check text-success"></i>
                    <i v-else class="fe fe-x text-danger"></i>
                  </td>
                  <td class="text-nowrap">
                    {{moment.utc(item.updated_at).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.INGREDIENTS_VIEW])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        data-test="link-dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.INGREDIENTS_VIEW)"
                          :to="`/ingredients/${item.id}`"
                          data-test="link-edit"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="6">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {mainIngredients} from '@/services';

const TOOLTIP_ALLOWED_IN_RECIPE = 'Whether this ingredient will be displayed for recipe developers or not.';
const TOOLTIP_TAGS = 'Those tags are attached to this ingredient, when writing a recipe and selecting this ingredient it will auto associate tags to the recipe.';

export default {

  components: {

    Pagination,
  },
  mixins: [

    list,
  ],

  data() {
    return {
      TOOLTIP_ALLOWED_IN_RECIPE,
      TOOLTIP_TAGS,
    };
  },
  methods: {

    fetchData(page) {
      return mainIngredients.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        with: 'category,tags',
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting ingredient cannot be undone. Are you sure that you want to delete this ingredient?')) {
        await mainIngredients.deleteById(item.id);
        this.refresh();
      }
    },
  },
};

</script>
